import styles from './ExternalStakeholderRow.module.scss';
import { Badge, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { IconButton } from '../../../../components/IconButton/IconButton';
import cn from 'classnames';
import { InitialLetterBadge } from 'components/InitialLetterBadge/InitialLetterBadge';
import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Loader } from 'components/Loader/Loader';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { Checkbox } from 'components/FormComponents/Checkbox/Checkbox';
import { AlertMessage } from 'templates/AppLayout/components/AlertMessage/AlertMessage';
import { loader } from 'graphql.macro';

const DELETE_JOURNEY = loader("src/graphql/mutations/delete_stakeholder_journey.graphql")

export const ExternalStakeholderRow = ({setLoading, externalStakeholder, query, handleArchive, selected, setSelected, showing , handleUnarchive, handleDelete, alert, sendReminder, tagWatch }) => {

  const [open, setOpen] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false)
  const [deleteModal , setDeleteModal]  = useState(false)
  const [deleteJourneyModal, setDeleteJourneyModal] = useState(false)
  const [stakeholder, setStakeholder] = useState(null);
  const showingArchived = showing === 'archived'
  const [displayMessage , setDisplayMessage] = useState(false)
  const [filteredStakeholder , setFilteredStakeholder] = useState(stakeholder)
  const [timeoutId , setTimeoutId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const [fetchExternalStakeholder, { loading: fetching }] = useLazyQuery(query, {
    variables: { id: externalStakeholder.id },
    onCompleted: (data) => {
      setStakeholder(data.externalStakeholder);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const [deleteJourney, { loading: deleting }] = useMutation(DELETE_JOURNEY, {
    onCompleted: (data) => {
      alert('Journey was successfully deleted')
      setStakeholder(prevStakeholder => ({
        ...prevStakeholder,
        stakeholderJourneys: prevStakeholder.stakeholderJourneys.filter(
          journey => journey.id !== data?.deleteStakeholderJourney?.id
        )
      }));
    }
  })

  useEffect(()=>{
    setLoading(deleting)
  },[deleting])

  useEffect(()=>{
    if(stakeholder && tagWatch !== undefined && tagWatch !== 'empty' ){
        const filteredJourneys = stakeholder.stakeholderJourneys.map((journey) => {
          const filteredBlocks = journey.blockResults.filter((blockResult) => {
            return blockResult.block.stakeholderTag.name === tagWatch;
          });
          if (!filteredBlocks.length) {
            return null;
          }
          return {
            ...journey,
            blockResults: filteredBlocks,
          };
        });
      
        const filteredJourneysWithoutNull = filteredJourneys.filter((journey) => journey !== null);

        const isFilteredDataChanged = JSON.stringify(stakeholder.stakeholderJourneys) !== JSON.stringify(filteredJourneysWithoutNull);

        if (isFilteredDataChanged) {
          const updatedStakeholder = {
            ...stakeholder,
            stakeholderJourneys: filteredJourneysWithoutNull,
          };
          setFilteredStakeholder(updatedStakeholder);
        }

    }else{
      setFilteredStakeholder(stakeholder)
    }
  },[tagWatch, stakeholder])

  const handleOpen = () => {
    if(!open && !stakeholder) {
      fetchExternalStakeholder();
    }
    setOpen(!open);
  }

  const handleBell = () =>{
    if(displayMessage){
      clearTimeout(timeoutId)
      setTimeoutId(null)
    }else{
      const id = setTimeout(()=>{
        sendReminder({variables:{id : externalStakeholder.id}})
        setDisplayMessage(false)
      },5000);
      setTimeoutId(id)
    }
    setDisplayMessage(prevState => !prevState)
  }

  const handleBadge = (blockResult) => {
    return blockResult?.status === 'completed' ? styles.active : styles.inactive
  }

  const handleCheckboxClick = () => selected?.includes(externalStakeholder?.id) ? setSelected((selected)=> selected.filter((id)=>id !==externalStakeholder?.id)) : setSelected([...selected,externalStakeholder?.id])

  const latestCreatedAt = externalStakeholder?.stakeholderJourneys.reduce((latest, journey) => {
    const createdAt = new Date(journey.createdAt).getTime();
    if (!latest || createdAt > latest) {
      return createdAt
    }
    return latest;
  }, null);

  const handleQuizJourneyFlag = (stakeholderJourney) =>{
    const quizResult = stakeholderJourney.blockResults?.filter(bR=> bR.block.typeOf ==='quiz')
    if (!quizResult?.length || stakeholderJourney.status !=='completed'){
      return
    }
    const passed = stakeholderJourney.quizPassed

    return <Badge className={styles.badge} bg={`${passed?'success':'danger'}`}>{`Quiz ${passed ? 'Passed': 'Failed'}`}</Badge>
    
  }

  const handleJourneyDelete=(id)=>{
    deleteJourney({variables: {id: id}})
    setDeleteId(null)
  }

  return (
    <div className={cn(styles.root, open ? 'padding-bottom-0' : null)}>
      <AlertMessage visible={displayMessage} buttonClass={cn(styles.button)} type='Success' message='Journey Reminder Successfully Sent.' buttonMessage='Undo' onClickMessageButton={()=>{
        setDisplayMessage(false)
        clearTimeout(timeoutId)
        setTimeoutId(null)
        }} onClose={()=> alert(null,null)}/>
      <ConfirmationModal
        title="Delete stakeholder journey"
        buttonTitle='Delete'
        subtitle={`Are you sure you want to delete the journey?`}
        visible={deleteJourneyModal}
        onConfirmation={() => {
          handleJourneyDelete(deleteId)
          setDeleteId(null)
          setDeleteJourneyModal(false)
        }}
        onClose={() => {
          setDeleteJourneyModal(false)
          setDeleteId(null)
        }}
                />
      <ConfirmationModal 
        visible={archiveModal || deleteModal}
        title= {`${deleteModal ? 'Delete' : archiveModal && showingArchived ? 'Unarchive' : 'Archive'} Stakeholder Confirmation`}
        subtitle={`This Stakeholder will be ${deleteModal ? 'completely Deleted': archiveModal && showingArchived ? 'Unarchived' : 'Archived' }`}
        confirmationIcon={ deleteModal ? 'trash-2' :"archive"}
        onClose={() => {
          setArchiveModal(false);
          setDeleteModal(false);
        }}
        onConfirmation={()=>{
          deleteModal && handleDelete(externalStakeholder?.id, false)
          showingArchived && !deleteModal ? handleUnarchive(externalStakeholder?.id,false) :
          !deleteModal && handleArchive(externalStakeholder?.id,false)
          setArchiveModal(false)
          setDeleteModal(false)
        }}
        buttonTitle="Yes"
      />
      {/* Status */}
      <Checkbox className={styles.checkbox} onClick={handleCheckboxClick} checked={selected?.includes(externalStakeholder?.id)}/>
      <span className={styles.statusParent}>
        <Badge className={cn(styles.status, `${externalStakeholder?.status !=='archived' ? `badge--status-${externalStakeholder?.status}`: styles.archived}` )}>{externalStakeholder?.status?.replace('_', ' ')}</Badge>
      </span>

      {/* Name */}
      <h1 className={cn(styles.name, 'title-6')}>
      <InitialLetterBadge word={externalStakeholder.stakeholderTag.name} color={externalStakeholder.stakeholderTag.color} />
        <Link to={`/profile/${externalStakeholder.id}`} className='link'>{externalStakeholder.name}</Link>
      </h1>
      
      {/* Team/Company */}
      <h1 className='title-6'>
        {externalStakeholder?.stakeholderJourneys[0]?.brand.name}
      </h1>
      
      {/* Start Date */}
      <Moment date={new Date(latestCreatedAt || externalStakeholder?.startDate)} format='DD/MM/YYYY' />

      {/* Actions */}
      <div className={cn(styles.actions, 'd-flex justify-content-between')}>
        {!showingArchived && <IconButton icon="bell" tip='Send Reminder' onClick={handleBell} /> }
        {/* <IconButton icon='trash-2' tip={`Delete`} onClick={() => setDeleteModal(true)} /> */}
        <IconButton icon='archive' tip={`${externalStakeholder?.archived ? 'Unarchive' : 'Archive'}`} onClick={() => setArchiveModal(true)} />
        <IconButton icon={`chevron-${open ? 'up' : 'down'}`} onClick={handleOpen} />
      </div>
      <Collapse in={open}>
        <div className={cn(styles.journeys)}>
          {fetching && (
            <Loader />
          )}
          {filteredStakeholder?.stakeholderJourneys && stakeholder?.stakeholderJourneys?.map((stakeholderJourney) => {
            const journey = stakeholderJourney.journey;
            const blocks = journey.journeyBlocks;
            const journeyInternals = stakeholderJourney.internalStakeholders
            const blocksResults = stakeholderJourney.blockResults.sort((a, b) => {return a.blockId - b.blockId});

            let filteredBlocks = [];
            if (tagWatch === 'empty' || tagWatch === undefined || !tagWatch) {
              filteredBlocks = blocks;
            } else {
              filteredBlocks = blocks.filter((b) => b.block.stakeholderTag?.name === tagWatch);
            }

            return (
              <div key={stakeholderJourney.id}>
                <div className={cn(styles.journeyTitle, styles.journeyRow, 't-body')}>
                  <div className={styles.info}>
                    <div>{`${journey.name} (${filteredBlocks?.length})`}</div>
                    {handleQuizJourneyFlag(stakeholderJourney)}
                  </div>
                  <Moment date={stakeholderJourney.createdAt} format='DD/MM/YYYY' />
                  <div className={styles.journeyDelete}>
                    <IconButton tip={`Delete Journey`} onClick={()=>{
                      setDeleteJourneyModal(true)
                      setDeleteId(stakeholderJourney.id)
                      }} icon='trash-2' className={styles.icon}/>
                  </div>
                </div>
                {filteredBlocks.map((block) => {
                  const blockResultArray = blocksResults.filter((e) => e.block.id === block.block.id);
                  const blockResult = blockResultArray[0] || null;
                  const histories = blockResult?.histories;
                  const badge = blockResult?.answeredFields
                  const activeBadge = handleBadge(blockResult)
                  const isInternal = block.block.stakeholderTag?.typeOf === 'internal'
                  const internalStakeholder =  journeyInternals?.find((s)=> (s?.stakeholderTag?.typeOf === blockResult?.block?.stakeholderTag?.typeOf) && (s?.stakeholderTag?.id === blockResult?.block?.stakeholderTag?.id))
                  const statusColor = blockResult?.status === 'locked' ? 'in_progress' : blockResult?.status
                  return (
                    <div key={block.id} className={cn(styles.journeyRow)}>
                      <div className={cn(styles.blockItem, styles.blockStatus)}>
                        <Badge 
                          className={cn(styles.status, `badge--status-light-${statusColor}`)}
                        >
                          {blockResult?.status.replace('_', ' ')}
                        </Badge>
                      </div>
                      <div className={cn(styles.blockItem, styles.blockName, 't-color-secondary')}>
                        <InitialLetterBadge word={block.block.stakeholderTag.name} color={block.block.stakeholderTag.color} />
                        {block.block.name}
                      </div>
                      <div className={cn(styles.blockItem, styles.blockRequired)}>
                        {block.required ? 'Required' : 'Nice to do'}
                      </div>
                      <div className={cn(styles.blockItem, styles.blockResult, 't-color-secondary')}>
                        {!!isInternal &&
                          <div> 
                            <span style={{color: internalStakeholder?.stakeholderTag?.color }} >{internalStakeholder?.stakeholderTag?.name}</span>
                            {` • `}
                            <Link to={`/profile/${externalStakeholder.id}`} className='link'>{internalStakeholder?.name}</Link>
                          </div>
                        }
                      </div>
                      {(open) && (
                        <div className={cn(styles.badgeContainer)}>
                          <div className={cn(styles.blockItem, styles.blockBadge, activeBadge)}>
                            {badge}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}