import { useRef } from 'react';
import { useClickOutside } from '../../hooks/click-outside';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import styles from './Modal.module.scss';

export const Modal = ({ 
  visible,
  className, 
  title,
  subtitle,
  children,
  onClose,
  customStyles = {
    backgroundColor: '#FFF',
    titleColor: '#333333',
  },
  cardClassname,
  headerClassName,
  titleContainerClassName,
  titleClassName,
  closeButtonClassName,
  rootClassName
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, onClose);

  return (
    <div className={cn(styles.root, { [styles.rootVisible]: visible }, {[rootClassName]: rootClassName})}>
      <div className={cn(styles.card, 'card', 'card-with-shadow', {[cardClassname]: cardClassname})} ref={wrapperRef} style={{ backgroundColor: customStyles.backgroundColor }}>
        <div className={cn(styles.header, {[headerClassName]: headerClassName})}>
          <div className={cn('mw-100', {[titleContainerClassName]: titleContainerClassName})}>
            <h1 className={cn('title-3', {[titleClassName]: titleClassName})} style={{ color: customStyles.titleColor }}>{title}</h1>
            {subtitle && <p className='t-subtitle mt-2'>{subtitle}</p>}
          </div>
          <FeatherIcon icon="x" onClick={onClose} className={cn(styles.closeBtn,{[closeButtonClassName]: closeButtonClassName})}/>
        </div>
        <div className={cn('w-100', { [className]: className })}>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func
};