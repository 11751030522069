import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEnsureStakeholderLayoutContext } from 'templates/EnsureStakeholderLayout/EnsureStakeholderLayout';

const SidebarContext = createContext(null);

const RECORDS_QUERY = loader('src/graphql/queries/external_stakeholder_record_blocks_info.graphql')

export const SidebarProvider = ({ children }) => {
  const [hidden, setHidden] = useState(false);
  const [records, setRecords] = useState([])

  const {setLoading, triggerUnexpectedError} = useEnsureStakeholderLayoutContext()

  const [fetchRecords, { loading: fetchingRecords }] = useLazyQuery(RECORDS_QUERY, {
    variables: {fieldType: 'record'},
    onCompleted: data =>{
      setRecords(data.externalStakeholderRecordBlocksInfo)
    } 
  });

  useEffect(()=>{
    fetchRecords({variables:{fieldType: 'record'}})
  },[])

  useEffect(()=>{
    setLoading(fetchingRecords)
  },[fetchingRecords])


  const toggleSidebar = () => setHidden((prev) => !prev);

  return (
    <SidebarContext.Provider
      value={{
        data: records || null,
        refetchRecords: fetchRecords,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }
  return context;
};
