//@ts-nocheck
import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import styles from './StakeholderJourneysScreen.module.scss';
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Select } from "components/FormComponents";
import { Input } from "components/FormComponents";
import cn from 'classnames';
import ProgressBar from "@ramonak/react-progress-bar";
import { CustomizedCard } from "components/CustomizedCard/CustomizedCard";
import fuzzysort from "fuzzysort";
import { titleize } from "utils/helpers";
import { useNavigate } from "react-router-dom"
import { StakeholderJourney } from "../StakeholderFrameworkJourneyScreen/interfaces/StakeholderJourney";
import { StakeholderBlock } from "../StakeholderFrameworkJourneyScreen/interfaces/StakeholderBlock";

const JOURNEYS_QUERY = loader("src/graphql/queries/external_stakeholder_stakeholder_journeys.graphql")

export const EnsureStakeholderJourneysScreen = () =>{

    const [stakeholderJourneys, setStakeholderJourneys] = useState<StakeholderJourney[]>([])
    const [stakeholderJourney, setStakeholderJourney] = useState<StakeholderJourney>()
    const [stakeholderBlocks, setStakeholderBlocks] = useState<StakeholderBlock[]>([])

    const {setLoading} = useEnsureStakeholderLayoutContext()
    const navigate = useNavigate();

    const {
        register,
        control,
        setValue,
        } = useForm({
            reValidateMode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
            defaultValues:{
                filterStatus: 'empty',
                searchRecord: '',
                journey: stakeholderJourneys[0]?.id
            }
      });

    const { loading: fetching } = useQuery(JOURNEYS_QUERY, {
        onCompleted: (data) => {
            setStakeholderJourneys(data?.externalStakeholderStakeholderJourneys)
        }
      });

    const journeyWatch = useWatch({ control, name: "journey" });
    const searchWatch = useWatch({ control, name: "searchRecord" });
    const filterStatusWatch = useWatch({control, name: 'filterStatus'})

    useEffect(() => {
        let filteredBlocks = stakeholderJourney?.stakeholderBlocks || [];
    
        if (searchWatch?.trim()) {
            const searchResults = fuzzysort.go(searchWatch, filteredBlocks, { key: "block.name", threshold: -10000 });
            filteredBlocks = searchResults.map(result => result.obj as StakeholderBlock);
        }
    
        if (filterStatusWatch && filterStatusWatch !== 'empty') {
            filteredBlocks = filteredBlocks.filter(sj => sj.result.status === filterStatusWatch);
        }
    
        setStakeholderBlocks(filteredBlocks);
    }, [searchWatch, filterStatusWatch]);
        
    useEffect(()=>{
        setLoading(fetching)
     },[fetching])

    useEffect(()=>{
        const sJourney = stakeholderJourneys?.find((f)=> `${f.id}` === journeyWatch)
        setStakeholderJourney(sJourney)
        setStakeholderBlocks(sJourney?.stakeholderBlocks)
    },[journeyWatch])

    const completedBlocks = stakeholderJourney?.stakeholderBlocks?.filter((sB)=> sB.result.status === 'completed')?.length || 0
    const totalBlocks = stakeholderJourney?.stakeholderBlocks?.length || 0

    const statuses = stakeholderJourney?.stakeholderBlocks?.map(sB => sB.result.status)
    const uniqueStatuses = [...new Set(statuses)];

    const completedPercentage = () => {
        return Math.floor((totalBlocks > 0) ? (completedBlocks / totalBlocks) * 100 : 0);
    }
    
    return(
        <>
            <EnsureStakeholderLayout.Header className={styles.root}>
                <EnsureStakeholderLayout.Title>
                    <div className={styles.header}>
                        <Select withDifferentIcon differentIconClassName={styles.icon} register={register} className={styles.select} name='journey' buttonStyles={styles.frameworkSelector}>
                            {stakeholderJourneys?.map(sj => (
                                <Select.Item
                                key={sj.id}
                                value={sj.id}
                                active={stakeholderJourneys[0].id}
                                >
                                {sj.name}
                                </Select.Item>
                            ))}
                        </Select>                            
                        <div className={styles.footer}>
                            <Input name="searchRecord" className={cn(styles.search)} register={register} inputClassName={styles.input} type="text" icon="search" placeholder="Search" />
                            <Select labelPrefix="Filter by status" register={register} className={styles.search} name='filterStatus'>
                                <Select.Item value="empty" active={filterStatusWatch === "empty"}>
                                    Any
                                </Select.Item>
                                {uniqueStatuses?.map(s => (
                                    <Select.Item
                                    key={s}
                                    value={s}
                                    active={filterStatusWatch === s}
                                    >
                                    {`${titleize(s)}`}
                                    </Select.Item>
                                ))}
                            </Select>                            

                        </div>
                    </div>
                </EnsureStakeholderLayout.Title>
            </EnsureStakeholderLayout.Header>
            <EnsureStakeholderLayout.BodyWithRightBar>
                <div className={styles.body}>
                    <div className={styles.container}>
                        <div className={styles.innerContainer}>
                            <div className={styles.text}>
                               <span className={styles.number}>{`${completedBlocks}/${totalBlocks}`}</span>
                                <span className={styles.string}> Blocks Completed</span>
                            </div>
                            <div className={styles.progressBar}>
                                <ProgressBar className={styles.progressBarLabel} labelAlignment="center" bgColor="linear-gradient(to right, #469db2, #134f5c)" maxCompleted={100} completed={completedPercentage()} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.journeys}>
                        {stakeholderBlocks?.map((sj)=>{
                            return(
                                <CustomizedCard onClick={() => navigate(`/journey/${stakeholderJourney?.token}/block/${sj.journeyBlockId}`, {
                                    state: { referrer: window.location.pathname } // Pass the referrer as state
                                })} status={sj.result.status} middleTitle={sj.block.name} bottomTitle={`bottom`} completedRatio={sj.result.answeredFields}/>
                            )
                        })}
                    </div>
                </div>
            </EnsureStakeholderLayout.BodyWithRightBar>
        </>
    )
}