//@ts-nocheck
import styles from './RecordsBar.module.scss';
import cn from 'classnames'
import FeatherIcon from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import { List } from '../RightBar/Components/List/List';
import { useNavigate } from "react-router-dom";
import { IconButton } from 'components/IconButton/IconButton';
import { useSidebarContext } from 'components/RecordSideBar/RecordSideBar';
import { Card } from '../../StakeholderBlockRecordScreen/components/Card/Card';
import moment from 'moment';
import { MobileRecordBar } from 'templates/EnsureStakeholderLayout/components/MobileRecordBar/MobileRecordBar';

export const RecordsBar = ({
  stakeholder,
  onStateChange = () => {},
  hidden,
  setHidden = () => {},
  rightBarClassNames
}) => {

  // const [hidden, setHidden] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => onStateChange(hidden), [hidden]);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const navigate = useNavigate();

  const { data } = useSidebarContext();

  const upcomingRecords = data?.filter(rec=> !rec.overdue)
  const overdueRecords  = data?.filter(rec=> rec.overdue)

  return (
    <>
        <div 
          className={styles.floatingButton} 
          onClick={() => setHidden(!hidden)}
        >
          <FeatherIcon icon="file-plus" />
          <div className={styles.badge}>{overdueRecords?.length}</div>
        </div>

        {isMobile &&
          <MobileRecordBar upcomingRecords={upcomingRecords}
          overdueRecords={overdueRecords}
          styles={styles}
          navigate={navigate}
          display={isMobile && !hidden}
          setHidden={setHidden}
          />
        }

      {/* Sidebar */}
    <aside className={cn(styles.root, { [styles.rootHidden]: hidden, [rightBarClassNames]: rightBarClassNames })}>
      <div>
        <div className={cn(styles.header, { [styles.headerHidden]: hidden })}>
          <div className={cn(styles.titleIcon,  { [styles.headerHidden]: hidden })}>
            <FeatherIcon icon='file-plus'/>
            <div className={cn(styles.title)}>Record Reminders</div>
          </div>

          <IconButton icon='x' onClick={()=> setHidden(!hidden)}/>
        </div>
        <Tabs navItemStyles={styles.navItemStyles} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} defaultActiveKey="upcoming">
          <Tab eventKey="upcoming" title="Upcoming">
            {!!upcomingRecords?.length && 
              <div className={cn(styles.container)}>
                  {upcomingRecords?.map((record)=>{
                    const formattedDueDate = moment(record.nextDueDate).format("DD/MM/YYYY");
                    return(
                      <div className={styles.tile}>
                        <Card
                        topName={`${record.journeyName} • ${record.blockName}`}
                        middleName={record.fieldText}
                        bottomName={`Due ${formattedDueDate}`}
                        rootClassName={styles.card}
                        rootOnClick={()=>{
                          navigate(`/block_records/${record.blockId}/${record.stakeholderJourneyId}/${record.fieldId}`,{
                            state: {blockName: record.blockName, fieldText: record.fieldText, journeyName: record.journeyName}}
                            )
                        }}
                        onClick={()=>{
                          navigate(`/block_records/${record.blockId}/${record.stakeholderJourneyId}/${record.fieldId}`,{
                          state: {blockName: record.blockName, fieldText: record.fieldText, journeyName: record.journeyName}}
                          )
                        }}
                        />
                      </div>
                    )
                  })}
              </div>
            }
          </Tab>
          <Tab eventKey="overdue" title="Overdue">
            {!!overdueRecords?.length &&
              <div className={cn(styles.container)}>
            {overdueRecords?.map((record)=>{
                  const formattedDueDate = moment(record.nextDueDate).format("DD/MM/YYYY");
                  return(
                    <div className={styles.tile}>
                      <Card
                      topName={`${record.journeyName} • ${record.blockName}`}
                      middleName={record.fieldText}
                      bottomName={`Due ${formattedDueDate}`}
                      rootClassName={styles.card}
                      rootOnClick={()=>{
                        navigate(`/block_records/${record.blockId}/${record.stakeholderJourneyId}/${record.fieldId}`,{
                          state: {blockName: record.blockName, fieldText: record.fieldText, journeyName: record.journeyName}}
                          )
                      }}
                      onClick={()=>{
                        navigate(`/block_records/${record.blockId}/${record.stakeholderJourneyId}/${record.fieldId}`,{
                        state: {blockName: record.blockName, fieldText: record.fieldText, journeyName: record.journeyName}}
                        )
                      }}
                      />
                    </div>
                    )
                })}
              </div>
            }
          </Tab>
        </Tabs>
      </div>
    </aside>
    </>
  )
}
