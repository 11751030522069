import styles from './ActionsMenu.module.scss';
import { useState } from 'react';
import { IconButton } from 'components/IconButton/IconButton'
import { useClickOutside } from 'hooks/click-outside';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import { useRef } from 'react';

export const ActionsMenu = ({ 
  onCancel = () => {},
  onDelete = () => {}
}) => {

  const actions = [
    { title: 'Delete', icon: 'trash-2', callback: onDelete },
  ]

  const ref = useRef(null);

  const [opened, setOpened] = useState(false);

  useClickOutside(ref, () => setOpened(false));

  return (
    <div ref={ref} className={cn(styles.root, 'p-relative', 'd-flex', 'align-items-center', { [styles.rootOpened]: opened })}>
      <IconButton icon='more-vertical' className='margin-left' onClick={() => setOpened(!opened)}/>
      <ul className={cn(styles.menu, 'card', 'card-with-shadow', 'card-with-border')}>
        {actions.map(action => (
          <li onClick={action.callback} key={action.title} className={styles.item}><FeatherIcon className='margin-right--small' size={15} icon={action.icon} /> {action.title}</li>
        ))}
      </ul>
    </div>
  )
}