import cn from 'classnames';
import styles from './StakeholderExists.module.scss';
import { useNavigate } from "react-router-dom";
import { CustomButton } from 'views/StakeholderJourneyScreens/components/CustomButton/CustomButton';
import moment from 'moment';

export const StakeholderExits = ({stakeholder, sameCompany, hasPassword, otherCompany, data, createCandidate}) =>{
  const navigate = useNavigate();

  const createNewGrower = () =>{
    if (data?.externalStakeholderAttributes){
      const date = `${moment(new Date()).format("DD/MM/YYYY")}`
      data.externalStakeholderAttributes['startDate'] = date
      data.externalStakeholderAttributes['email'] = data.externalStakeholderAttributes['email'].toLowerCase();
      createCandidate({variables:{ externalStakeholderAttributes: data.externalStakeholderAttributes, forceCreate: true}})
    }
  }

    return(
        <div className={cn(styles.content, 'card_content')}>
          <p className={styles.text}>A Grower with same email <span className={styles.span}>{stakeholder.email}</span> already exists in {sameCompany ? 'this company': <span className={styles.span}>{otherCompany}</span>}</p>
          <div className={cn('d-flex justify-content-between', styles.btns)}>
            {hasPassword ? 
              <CustomButton onClick={()=> navigate('/grower_sign_in')}>Login</CustomButton>
              : 
              <CustomButton onClick={()=>{
                navigate(`/grower_forgot_password`, {
                  state: {text: 'Set your new password', email: data?.externalStakeholderAttributes?.email}
                })
              }}>Set Password</CustomButton>
            }{
              otherCompany &&
              <CustomButton onClick={createNewGrower}>Create New Grower </CustomButton>
            }
          </div>
        </div>
    )
}