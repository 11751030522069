//@ts-nocheck
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import cn from 'classnames'
import styles from './StakeholderFrameworkJourneyScreen.module.scss'
import { useNavigate } from "react-router-dom";
import { IconButton } from "components/IconButton/IconButton";
import { Input } from "components/FormComponents";
import { Select } from "components/FormComponents";
import { useForm, useWatch } from "react-hook-form";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { StakeholderJourney } from "./interfaces/StakeholderJourney";
import { BlockCardPreview } from "views/StakeholderJourneyScreens/components/BlockCardPreview/BlockCardPreview";
import { CustomizedCard } from "components/CustomizedCard/CustomizedCard";
import ProgressBar from "@ramonak/react-progress-bar";
import { titleize } from "utils/helpers";
import { StakeholderBlock } from "./interfaces/StakeholderBlock";
import fuzzysort from "fuzzysort";

const STAKEHOLDER_JOURNEY_QUERY = loader('src/graphql/queries/external_stakeholder_stakeholder_journey.graphql')

export const EnsureStakeholderFrameworkJourneyScreen = () =>{
    
    const [stakeholderJourney , setStakeholderJourney] = useState<StakeholderJourney>()
    const [stakeholderBlocks, setStakeholderBlocks] = useState<StakeholderBlock[]>([])

    const navigate = useNavigate()
    const params = useParams()
    const { setLoading } = useEnsureStakeholderLayoutContext()

    const { loading: fetching } = useQuery(STAKEHOLDER_JOURNEY_QUERY, {
        variables: {stakeholderJourneyId: params.stakeholderJourneyId},
        onCompleted: data => {
            setStakeholderJourney(data.externalStakeholderStakeholderJourney)
        },
    });

    const {
        register,
        control,
        setValue,
        } = useForm({
            reValidateMode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
            defaultValues:{
                filterStatus: 'empty'
            }
      });
    
    const filterStatusWatch = useWatch({control, name: 'filterStatus'})
    const searchWatch = useWatch({ control, name: "searchRecord" });

    const totalBlocks = stakeholderJourney?.stakeholderBlocks?.length || 0
    const completedBlocks = stakeholderJourney?.stakeholderBlocks?.filter((f)=> f.result.status === 'completed')?.length || 0

    const statuses = stakeholderJourney?.stakeholderBlocks?.map((sb)=> sb.result.status)
    const uniqueStatuses = [...new Set(statuses)];


    const completedPercentage = () => {
        return Math.floor((totalBlocks > 0) ? (completedBlocks / totalBlocks) * 100 : 0);
    }

    useEffect(()=>{
        setLoading(fetching)
    },[fetching])

    useEffect(() => {
        let filteredStakeholderBlocks = stakeholderJourney?.stakeholderBlocks || [];
    
        if (searchWatch?.trim()) {
            const searchResults = fuzzysort.go(searchWatch, filteredStakeholderBlocks, { key: "block.name", threshold: -10000 });
            filteredStakeholderBlocks = searchResults.map(result => result.obj as StakeholderBlock);
        }
    
        if (filterStatusWatch && filterStatusWatch !== 'empty') {
            filteredStakeholderBlocks = filteredStakeholderBlocks.filter(sj => sj.result.status === filterStatusWatch);
        }
    
        setStakeholderBlocks(filteredStakeholderBlocks);
    }, [searchWatch, filterStatusWatch, stakeholderJourney?.stakeholderBlocks]);

    return(
        <>
        <EnsureStakeholderLayout.Header className={styles.layoutHeader}>
            <EnsureStakeholderLayout.Title>
            <div className={cn(styles.headerContainer, 'd-flex justify-content-center align-items-start')}>
                <div className={cn(styles.innerContainer,'d-flex justify-content-start align-items-center')}>
                    <IconButton onClick={()=>{
                        navigate(`/grower_dashboard`)
                    }} tip={"Frameworks"} className={styles.icon} icon='chevron-down'/>
                    <div className={cn('d-flex justify-content-center align-items-center', styles.title)}>
                        <div className={styles.frameworkName}>
                            {stakeholderJourney?.frameworkName}
                        </div>
                        <div className={styles.journeyName}>
                            {stakeholderJourney?.name}
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Input name="searchRecord" className={cn(styles.search)} register={register} inputClassName={styles.input} type="text" icon="search" placeholder="Search" />
                    <Select placeholder={'Filter by status'} register={register} className={styles.search} name='filterStatus'>
                        <Select.Item value="empty" active={filterStatusWatch === "empty"}>
                            Filter by status (Any)
                        </Select.Item>
                        {uniqueStatuses?.map(s => (
                            <Select.Item
                            key={s}
                            value={s}
                            active={filterStatusWatch === s}
                            >
                            {`Filter by status (${titleize(s)})`}
                            </Select.Item>
                        ))}
                    </Select>                            

                </div>
            </div>
        </EnsureStakeholderLayout.Title>
    </EnsureStakeholderLayout.Header>
    <EnsureStakeholderLayout.BodyWithRightBar rightBarClassNames={styles.rightBar}>
        <div className={styles.body}>
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.text}>
                    <span className={styles.number}>{`${completedBlocks}/${totalBlocks}`}</span>
                        <span className={styles.string}> Blocks Completed</span>
                    </div>
                    <div className={styles.progressBar}>
                        <ProgressBar className={styles.progressBarLabel} labelAlignment="center" bgColor="linear-gradient(to right, #469db2, #134f5c)" maxCompleted={100} completed={completedPercentage()} />
                    </div>
                </div>
            </div>
            <div className={cn(styles.block)}>
                {
                stakeholderBlocks?.map((stakeholderBlock) => {
                    return (
                    <div className={cn(styles.card, "w-100")}>
                        <CustomizedCard
                        status={stakeholderBlock.result.status}
                        middleTitle={stakeholderBlock.block.heading || stakeholderBlock.block.name}
                        completedRatio={stakeholderBlock.result.answeredFields}
                        onClick={() => navigate(`/journey/${stakeholderJourney.token}/block/${stakeholderBlock.journeyBlockId}`, {
                            state: { referrer: window.location.pathname } // Pass the referrer as state
                        })}
                        />
                    </div>
                    )
                })
            }
            </div>
        </div>
    </EnsureStakeholderLayout.BodyWithRightBar>
    </>
    )
}