//@ts-nocheck
import { useParams } from "react-router-dom";
import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import styles from './StakeholderEvidenceEntriesScreen.module.scss'
import cn from 'classnames'
import { Button, Input, InputGroup, Textarea } from "components/FormComponents"
import FeatherIcon from 'feather-icons-react'; 
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { useForm } from "react-hook-form";
import fuzzysort from "fuzzysort";
import { IconButton } from "components/IconButton/IconButton";
import { useLocation } from "react-router-dom";
import { ModalForm } from "components/ModalForm/ModalForm";
import { Controller } from "react-hook-form";
import { FilePicker } from "components/FormComponents";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { convertLinksToClickHere } from "utils/helpers";

const ENTRIES_QUERY = loader("src/graphql/queries/external_stakeholder_field_entries.graphql");
const CREATE_ANSWER_MUTATION = loader("src/graphql/mutations/create_external_stakeholder_answer.graphql")
const UPDATE_ANSWER_MUTATION = loader("src/graphql/mutations/update_external_stakeholder_answer.graphql")
const DELETE_ANSWER_MUTATION = loader("src/graphql/mutations/delete_external_stakeholder_answer.graphql")

export const EnsureStakeholderEvidenceEntriesScreen=()=>{

    const [open, setOpen] = useState<boolean>(false)
    const [updating, setUpdating] = useState<boolean>(false)
    const [entries, setEntries] = useState([])
    const [filteredEntries, setFilteredEntries] = useState([])
    const [blockResultId, setBlockResultId] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [entryToDelete, setEntryToDelete] = useState(null)
    const [description, setDescription] = useState<string|null>(null)

    const params = useParams()
    const location = useLocation()

    const navigate = useNavigate()
 
    const {blockName, fieldText, journeyName} = location?.state

    const {register,formState:{errors}, control, watch, reset, handleSubmit} = useForm({reValidateMode: "onChange", criteriaMode: "firstError", shouldFocusError: true,
    });

    const {setLoading} = useEnsureStakeholderLayoutContext()

    const { loading: fetching } = useQuery(ENTRIES_QUERY, {
        variables: {blockId: params.blockId, fieldId: params.fieldId, stakeholderJourneyId: params.stakeholderJourneyId, fieldType: 'evidence'},
        onCompleted: data => {
            setEntries(data.externalStakeholderFieldEntries?.answers);
            setFilteredEntries(data.externalStakeholderFieldEntries?.answers);
            setBlockResultId(data.externalStakeholderFieldEntries?.blockResultId)
            setDescription(data.externalStakeholderFieldEntries?.description)
        },
      });

    const [createAnswer, { loading: creatingAnswer } ] = useMutation(CREATE_ANSWER_MUTATION, {
        onCompleted: async (data) => {
        const newEntries = [...entries , data?.createExternalStakeholderAnswer?.answer]
        setEntries(newEntries)
        onClose()
        },
        onError: (error) => {
        },
    });
    const [updateAnswer, { loading: updatingAnswer } ] = useMutation(UPDATE_ANSWER_MUTATION, {
        onCompleted: async (data) => {
            const updatedAnswer = data?.updateExternalStakeholderAnswer?.answer
            const updatedEntries = entries.map(entry =>
                entry.id === updatedAnswer.id ? { ...entry, ...updatedAnswer } : entry
            );
            setEntries(updatedEntries)
   
          onClose()
        },
        onError: (error) => {
            triggerUnexpectedError()
        },
    });

    const [deleteAnswer, { loading: deletingAnswer } ] = useMutation(DELETE_ANSWER_MUTATION, {
        onCompleted: async (data) => {
            const toDeleteId = data?.deleteExternalStakeholderAnswer?.id
            const remainingEntries = entries?.filter(e=> e.id !== toDeleteId)
            setEntries(remainingEntries)
        },
        onError: (error) => {
            triggerUnexpectedError()
        },
    });

    const search = watch('searchRecord')

    useEffect(() => {
        if (search !== null && search?.trim() !== "") {
          const filter = fuzzysort.go(search, entries, { key: "value.text", threshold: -10000 });
          const searchedEntries= [];
          filter.forEach((o)=>searchedEntries.push(o.obj))
          setFilteredEntries(searchedEntries)
        }else{
            setFilteredEntries(entries)
        }
      }, [search]);

    useEffect(()=>{
        setFilteredEntries(entries)
    },[entries])

    useEffect(()=>{
        setLoading(fetching || creatingAnswer || updatingAnswer || deletingAnswer)
    },[fetching, creatingAnswer, updatingAnswer, deletingAnswer])

    const onSubmit = (data) =>{

        let record = {}
        record['value'] = data.value;
        record['stakeholderJourneyBlockResultId'] = blockResultId
        record['fieldId']= params.fieldId;
        
        createAnswer({variables: {answerAttributes: record}})
    }

    const handleConditionalSubmit = (data) => {
        if (updating) {
            const updatedRecord = {
                id: data.id,
                fieldId: data.fieldId,
                stakeholderJourneyBlockResultId: blockResultId,
                value:{
                    text: data?.value?.text,
                    uploadId: data?.value?.uploadId
                }
            }
            updateAnswer({variables: {answerAttributes: updatedRecord}})
            return;
        }    
        onSubmit(data);
    };

    const onClose = () =>{
        setOpen(false)
        setUpdating(false)
    }
    return (
        <>
            <ConfirmationModal 
            visible={openModal}
            title= {`Permanently Delete Record`}
            subtitle={`This Record will be completely deleted.`}
            confirmationIcon={'trash-2'}
            onClose={() => {
                setOpenModal(false)
                setEntryToDelete(null)
            }}
            onConfirmation={()=>{
                setOpenModal(false)
                deleteAnswer({variables: {blockResultId: blockResultId, id: entryToDelete}})
                setEntryToDelete(null)
            }}
            buttonTitle="Delete Permanently"
        />
        <EnsureStakeholderLayout.Header className={styles.layoutHeader}>
            <EnsureStakeholderLayout.Title>
                <div className={cn(styles.headerContainer, 'd-flex justify-content-center align-items-start')}>
                    <div className={cn(styles.innerContainer,'d-flex justify-content-start align-items-center')}>
                        <IconButton onClick={()=>{
                            navigate(`/evidence/${params.blockId}/${params.stakeholderJourneyId}`,{
                                state: {blockName: blockName, fieldText: fieldText, journeyName: journeyName}
                            })
                        }} className={styles.icon} icon='chevron-down'/>
                        <div className={cn('d-flex justify-content-center align-items-center', styles.title)}>
                            <div className={styles.journeyName}>
                                {blockName}
                            </div>
                            <div className={styles.blockName}>
                                {fieldText}
                            </div>
                            <div className={styles.entries}>
                                {entries?.length} entries
                            </div>
                        </div>
                    </div>
                    {!!description && 
                        <div className={styles.description}>
                            <div className={styles.text}>
                                {convertLinksToClickHere(description)}
                            </div>
                        </div>
                    }
                    <div className={styles.searchAndButton}>
                        <Input name="searchRecord" className={cn(styles.search)} register={register} inputClassName={styles.input} type="text" icon="search" placeholder="Search" />
                        <Button className={styles.button} onClick={()=>{
                            reset({value:null})
                            setOpen(true)
                            }}>+ Add Evidence</Button>
                    </div>
                </div>
            </EnsureStakeholderLayout.Title>
        </EnsureStakeholderLayout.Header>
        <EnsureStakeholderLayout.Body>
            <>
                <ModalForm visible={open} onClose={onClose}>
                    <div className={styles.formHeader}>
                        <div className={styles.title}>
                            <FeatherIcon className={styles.icon} icon='file-plus'/>
                            <div className={styles.text}>{updating ?'Update' : 'Add'} Evidence</div>
                        </div>
                        <IconButton icon='x' onClick={onClose}/>
                    </div>
                    <form className={cn(styles.root)} onSubmit={handleSubmit(handleConditionalSubmit)}>
                        <div className={styles.comment}>
                            <InputGroup titleStyles={styles.text} title='Evidence Comment'>
                                <Textarea
                                name="value.text"
                                placeholder='Enter comment'
                                register={register}
                                validators={{required: true}}
                                className={styles.inputtt}
                                error={errors?.value?.text?.type}
                                dynamicHeight
                                />
                            </InputGroup>
                        </div>
                        <div className={styles.record}>
                            <InputGroup titleStyles={styles.text} title='Upload Evidence'>
                                <Controller 
                                    control={control}
                                    name={'value.uploadId'}
                                    render={({ field: { onChange, value } }) => (
                                        <FilePicker 
                                            onChange={onChange}
                                            value={value}
                                            error={errors?.value?.uploadId?.type}
                                        />
                                    )}
                                    rules={{ required: true }}
                                    />
                            </InputGroup>
                        </div>
                        <div className={styles.footer}>
                            <Button className={styles.button} submit >{updating ? 'Update' :'Save'} Evidence</Button>
                        </div>
                    </form>
                </ModalForm>
                <div className={styles.block}>
                    {
                        filteredEntries?.map((entry)=>{
                            const formattedTime = moment.utc(entry.createdAt).local().format("MM/DD/YYYY [@] h:mm a");

                            const contentType = new URL(entry?.upload?.url).searchParams.get('response-content-type');
                            const isImage = contentType.includes('image')
                            
                            return(
                                <div className={cn("card", "card-with-border", styles.rootCard)}>
                                    <div className={cn("card_content", styles.content)}>
                                        <div className={styles.container}> 
                                            <div className={cn(styles.info, 'd-flex justify-content-center align-items-start')}>
                                                <div className={styles.date}>Added {formattedTime}</div>
                                                <div className={styles.text}>{entry?.value?.text}</div>
                                                {/* <div>Entries</div> */}
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle className={cn(styles.dropdownToggle)}>
                                                    <IconButton icon='more-vertical' className={styles.iconButton}/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={cn(styles.dropdownMenu)}>
                                                    <Dropdown.Item
                                                        className={cn(styles.menuItem)}
                                                        href="#"
                                                        onClick={()=>{
                                                            setUpdating(true)
                                                            reset(entry)
                                                            setOpen(true)
                                                        }}
                                                        >
                                                        Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        className={cn(styles.menuItem)}
                                                        href="#"
                                                        onClick={() => {
                                                            setEntryToDelete(entry.id)
                                                            setOpenModal(true)
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className={styles.upload}>
                                            <div className={styles.iframeContainer}>
                                                { isImage
                                                 ?
                                                    <img scrolling="no" seamless="seamless" className={styles.iframe} src={`${entry?.upload?.url}`}/>
                                                 :  
                                                    <iframe scrolling="no" seamless="seamless" className={styles.iframe} src={`${entry?.upload?.url}`}/>
                                                }
                                            </div>
                                            <div className={styles.fileContainer}>
                                                <div className={styles.text}> {entry?.upload?.filename}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        </EnsureStakeholderLayout.Body>
    </>
    )
}