// @ts-nocheck

import { Select } from "components/FormComponents"
import { RecordSettingsProps } from "./interfaces/RecordSettingsProps"
import { EditableSettings } from "components/EditableSettings/EditableSettings"
import { InputGroup } from "components/FormComponents"
import styles from './RecordSettings.module.scss'
import cn from 'classnames'
import { ToggleSwitch } from "components/FormComponents"
import { Fragment } from "react"
import { Collapse } from "react-bootstrap"
import { Input } from "components/FormComponents"
import { Days } from "constants/days-enum"
import { ReminderFrequencies } from "constants/reminder-frequencies-enum"

export const RecordSettings: React.FC<RecordSettingsProps> = ({register, name, watch, recurring, field}) =>{
    const options = watch(`${name}`)

    return (
        <EditableSettings title='Settings' className='margin-top--large' startOpen={true}>
           {recurring && <>
           <div className={cn(styles.selects)}>
                <InputGroup titleStyles={styles.title} className={cn("w-100", styles.inputGroup)} title="Reminder Frequency">
                    <Select register={register} name={`${name}.reminderFrequency`} className='w-100'>
                        {ReminderFrequencies?.map(reminder=>
                            <Select.Item value={reminder.value} key={reminder.key} active={(options.reminderFrequency === reminder.value) || !options.reminderFrequency  }>{reminder.key}</Select.Item>
                        )}
                    </Select>
                </InputGroup>
                <InputGroup titleStyles={styles.title} className={cn("w-100", styles.inputGroup)} title="Day">
                    <Select register={register} name={`${name}.reminderDay`} className='w-100'>
                        {Days?.map(day=>
                            <Select.Item active={(options.reminderDay === day.value) || !options.reminderDay} value={day.value} key={day.key}>{day.key}</Select.Item>
                        )}
                    </Select>
                </InputGroup>
            </div>
            <hr />
            </>}

            <ToggleSwitch register={register} name={`${name}.required`} label='Required' />
            <hr />

            <ToggleSwitch register={register} name={`${name}.comment`} label='Allow Comment'/>
            <hr/>
            <Fragment>
            <ToggleSwitch register={register} name={`${name}.useDescription`} label='Add description' />
            <Collapse in={options?.useDescription}>
                <div><Input register={register} name={`${name}.description`} className='margin-top--small' placeholder='Enter description...'/></div>
            </Collapse>
            </Fragment>
            <hr/>

        </EditableSettings>
    )

}