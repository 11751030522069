//@ts-nocheck
import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout"
import styles from "./EnsureStakeholderBlockRecordScreen.module.scss"
import cn from 'classnames'
import { Input } from "components/FormComponents"
import FeatherIcon from 'feather-icons-react';
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { Block } from "./interfaces/block";
import { Blocks } from "./components/Blocks/Blocks";
import { useForm } from "react-hook-form";
import fuzzysort from "fuzzysort";

const RECORD_QUERY = loader("src/graphql/queries/external_stakeholder_records.graphql")
const BLOCKS_WITH_RECORDS_QUERY = loader("src/graphql/queries/external_stakeholder_blocks_with_records.graphql")

export const EnsureStakeholderBlockRecordScreen=()=>{
    const [blocks, setBlocks] = useState<Block[] | []>([])
    const [filterdBlocks, setFilteredBlocks] = useState<Block[] | []>([])

    const {register, watch} = useForm({reValidateMode: "onChange", criteriaMode: "firstError", shouldFocusError: true});

    const {setLoading} = useEnsureStakeholderLayoutContext()

    const { loading: fetching } = useQuery(BLOCKS_WITH_RECORDS_QUERY, {
        variables: {fieldType: 'record'},
        onCompleted: data => {
            setBlocks(data.externalStakeholderBlocksWithRecords);
        },
      });

    const search = watch('searchBlock')

    useEffect(()=>{
        setLoading(fetching)
    },[fetching])

    useEffect(() => {
        if (search !== null && search?.trim() !== "") {
          const filter = fuzzysort.go(search, blocks, { key: "blockName", threshold: -10000 });
          const searchedBlocks: Block[] = [];
          filter.forEach((o)=>searchedBlocks.push(o.obj as Block))
          setFilteredBlocks(searchedBlocks)
        }else{
            setFilteredBlocks(blocks)
        }
      }, [search]);
    
    useEffect(()=>{
        setFilteredBlocks(blocks)
    },[blocks])
                                                                           
    return (
        <>
        <EnsureStakeholderLayout.Header className={styles.layoutHeader}>
            <EnsureStakeholderLayout.Title>
                <div className={cn(styles.headerContainer)}>
                    <div className={cn('d-flex justify-content-center', styles.title)}>
                        <FeatherIcon icon='file-plus' className={styles.fileIcon} /> 
                        <p className={cn(styles.paragraph)}>Records</p>
                    </div>
                    <Input name="searchBlock" className={cn(styles.search)} register={register} inputClassName={styles.input} type="text" icon="search" placeholder="Search" />
                </div>
            </EnsureStakeholderLayout.Title>
        </EnsureStakeholderLayout.Header>
        <EnsureStakeholderLayout.BodyWithRightBar rightBarClassNames={styles.rightBar}>
            <>
                <Blocks blocks={filterdBlocks}/>
            </>
        </EnsureStakeholderLayout.BodyWithRightBar>
    </>
    )
}