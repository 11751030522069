import React, { useEffect, useState } from "react"
import { CustomizedCardProps } from "./interfaces/CustomizedCardProps"
import cn from 'classnames';
import styles from './CustomizedCard.module.scss'
import { Badge } from "react-bootstrap";
import { titleize } from "utils/helpers";
import trophy from 'assets/images/badgeactive.svg'
import inactiveTrophy from 'assets/images/badgeinactive.svg'

export const CustomizedCard:React.FC<CustomizedCardProps> = ({status, middleTitle, bottomTitle, completedRatio, onClick}) =>{

    return (
        <div className={cn(styles.root, 'card')} onClick={onClick}>
            <div className={styles.leftContainer}>
                <Badge className={cn(`badge--status-light-${status}`, styles.badge)}>{titleize(status)}</Badge>
                <div className={styles.middleTitle}>{middleTitle}</div>
                <div className={styles.bottomTitle}>{bottomTitle}</div>
            </div>
            <div className={styles.rightContainer}>
                <div className={styles.content}>
                    <div className={styles.ratio}>
                        {completedRatio}
                    </div>
                    <img className={styles.trophy} src={status === 'completed' ? trophy: inactiveTrophy} />
                </div>
            </div>
        </div>
    )
}