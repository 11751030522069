//@ts-nocheck
import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import styles from './StakeholderFrameworkScreen.module.scss';
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { useEffect, useState } from "react";
import { Frameworks } from "./interfaces/Frameworks";
import { StakeholderJourney } from "./interfaces/StakeholderJourney";
import { useForm, useWatch } from "react-hook-form";
import { Select } from "components/FormComponents";
import { Input } from "components/FormComponents";
import cn from 'classnames';
import ProgressBar from "@ramonak/react-progress-bar";
import { CustomizedCard } from "components/CustomizedCard/CustomizedCard";
import fuzzysort from "fuzzysort";
import { titleize } from "utils/helpers";
import { useNavigate } from "react-router-dom"

const FRAMEWORKS_QUERY = loader("src/graphql/queries/external_stakeholder_frameworks_journeys.graphql")

export const EnsureStakeholderFrameworkScreen = () =>{

    const [frameworks , setFrameworks] = useState<Frameworks[]>([])
    const [framework, setFramework] = useState<Frameworks | null>(null)
    const [stakeholderJourneys, setStakeholderJourneys] = useState<StakeholderJourney[]>([])
    
    const {setLoading} = useEnsureStakeholderLayoutContext()
    const navigate = useNavigate();

    const {
        register,
        control,
        setValue,
        } = useForm({
            reValidateMode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
            defaultValues:{
                filterStatus: 'empty'
            }
      });

    const { loading: fetching } = useQuery(FRAMEWORKS_QUERY, {
        onCompleted: (data) => {
            setFrameworks(data?.externalStakeholderFrameworksJourneys)
        }
      });

    const frameworkWatch = useWatch({ control, name: "framework" });
    const searchWatch = useWatch({ control, name: "searchRecord" });
    const filterStatusWatch = useWatch({control, name: 'filterStatus'})

    useEffect(() => {
        let filteredJourneys = framework?.stakeholderJourneys || [];
    
        if (searchWatch?.trim()) {
            const searchResults = fuzzysort.go(searchWatch, filteredJourneys, { key: "journeyName", threshold: -10000 });
            filteredJourneys = searchResults.map(result => result.obj as StakeholderJourney);
        }
    
        if (filterStatusWatch && filterStatusWatch !== 'empty') {
            filteredJourneys = filteredJourneys.filter(sj => sj.status === filterStatusWatch);
        }
    
        setStakeholderJourneys(filteredJourneys);
    }, [searchWatch, filterStatusWatch, framework?.stakeholderJourneys]);
        
    useEffect(()=>{
        setLoading(fetching)
     },[fetching])

    useEffect(()=>{
        const fWork = frameworks?.find((f)=> `${f.id}` === frameworkWatch)
        setFramework(fWork)
        setStakeholderJourneys(fWork?.stakeholderJourneys)
    },[frameworkWatch])


    const completedJourneys = framework?.stakeholderJourneys?.filter((sj)=>sj.status === 'completed')?.length || 0
    const totalJourneys = framework?.stakeholderJourneys?.length || 0

    const statuses = framework?.stakeholderJourneys?.map((sj)=> sj.status)
    const uniqueStatuses = [...new Set(statuses)];

    const completedPercentage = () => {
        return Math.floor((totalJourneys > 0) ? (completedJourneys / totalJourneys) * 100 : 0);
    }
     
    return(
        <>
            <EnsureStakeholderLayout.Header className={styles.root}>
                <EnsureStakeholderLayout.Title>
                    <div className={styles.header}>
                        <Select withDifferentIcon differentIconClassName={styles.differentIconClassName} register={register} className={styles.select} name='framework' buttonStyles={styles.frameworkSelector}>
                            {frameworks?.map(framework => (
                                <Select.Item
                                key={framework.id}
                                value={framework.id}
                                active={frameworks[0].id}
                                >
                                {framework.name}
                                </Select.Item>
                            ))}
                        </Select>                            
                        <div className={styles.footer}>
                            <Input name="searchRecord" className={cn(styles.search)} register={register} inputClassName={styles.input} type="text" icon="search" placeholder="Search" />
                            <Select labelPrefix={'Filter by Status'} register={register} className={styles.search} name='filterStatus'>
                                <Select.Item value="empty" active={filterStatusWatch === "empty"}>
                                    Any
                                </Select.Item>
                                {uniqueStatuses?.map(s => (
                                    <Select.Item
                                    key={s}
                                    value={s}
                                    active={filterStatusWatch === s}
                                    >
                                    {`${titleize(s)}`}
                                    </Select.Item>
                                ))}
                            </Select>                            

                        </div>
                    </div>
                </EnsureStakeholderLayout.Title>
            </EnsureStakeholderLayout.Header>
            <EnsureStakeholderLayout.BodyWithRightBar>
                <div className={styles.body}>
                    <div className={styles.container}>
                        <div className={styles.innerContainer}>
                            <div className={styles.text}>
                               <span className={styles.number}>{`${completedJourneys}/${totalJourneys}`}</span>
                                <span className={styles.string}> Journeys Completed</span>
                            </div>
                            <div className={styles.progressBar}>
                                <ProgressBar className={styles.progressBarLabel} labelAlignment="center" bgColor="linear-gradient(to right, #469db2, #134f5c)" maxCompleted={100} completed={completedPercentage()} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.journeys}>
                        {stakeholderJourneys?.map((sj)=>{
                            return(
                                <CustomizedCard onClick={()=> {
                                    navigate(`/framework/${framework.id}/${sj.id}`,{
                                        state: {frameworkName: framework?.name, frameworkId: framework?.id }
                                    })
                                }} status={sj.status} middleTitle={sj.journeyName} bottomTitle={`${sj.stakeholderBlocksCount} Blocks`} completedRatio={`${sj.completedBlocksCount}/${sj.stakeholderBlocksCount}`}/>
                            )
                        })}
                    </div>
                </div>
            </EnsureStakeholderLayout.BodyWithRightBar>
        </>
    )
}