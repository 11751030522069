import { Modal } from "components/Modal/Modal"
import { Tabs, Tab } from "components/Tabs/Tabs"
import moment from "moment"
import { Card } from "views/Ensure/StakeholderScreens/StakeholderBlockRecordScreen/components/Card/Card"
import cn from 'classnames'
import { useEffect, useState } from "react"
import cardStyles from './MobileRecordBar.module.scss'
import FeatherIcon from 'feather-icons-react'

export const MobileRecordBar =({styles, upcomingRecords, overdueRecords, navigate, display, setHidden})=>{
    const [open, setOpen] = useState(display)

    const title = () =>{
        return(
            <div className={cardStyles.title}>
                <FeatherIcon icon='file-plus'/>
                <div className={cardStyles.text}> Record Reminders</div>
            </div>
        )
    }

    useEffect(()=>{
        setOpen(display)
    },[display])
    
    return(
        <div>
            <Modal closeButtonClassName={cardStyles.closeButtonClassName} titleContainerClassName={cardStyles.titleContainerClassName} titleClassName={cardStyles.titleClassName} headerClassName={cardStyles.headerClassName} title={title()} rootClassName={cardStyles.root} cardClassname={cardStyles.cardClassname} onClose={()=>{
                setHidden(true)
            }} visible={open}>
                <Tabs navItemStyles={styles.navItemStyles} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} defaultActiveKey="upcoming">
                    <Tab eventKey="upcoming" title="Upcoming">
                        <div className={cn(styles.container)}>
                            {upcomingRecords?.map((record)=>{
                            const formattedDueDate = moment(record.nextDueDate).format("DD/MM/YYYY");
                            return(
                                <div className={styles.tile}>
                                    <Card
                                    topName={`${record.journeyName} • ${record.blockName}`}
                                    middleName={record.fieldText}
                                    bottomName={`Due ${formattedDueDate}`}
                                    onClick={()=>{
                                        navigate(`/block_records/${record.blockId}/${record.stakeholderJourneyId}/${record.fieldId}`,{
                                        state: {blockName: record.blockName, fieldText: record.fieldText, journeyName: record.journeyName}}
                                        )
                                    }}
                                    />
                                </div>
                            )
                            })}
                        </div>
                    </Tab>
                    <Tab eventKey="overdue" title="Overdue">
                        <div className={cn(styles.container)}>
                            {overdueRecords?.map((record)=>{
                                const formattedDueDate = moment(record.nextDueDate).format("DD/MM/YYYY");
                                return(
                                    <div className={styles.tile}>
                                        <Card
                                        topName={`${record.journeyName} • ${record.blockName}`}
                                        middleName={record.fieldText}
                                        bottomName={`Due ${formattedDueDate}`}
                                        onClick={()=>{
                                        navigate(`/block_records/${record.blockId}/${record.stakeholderJourneyId}/${record.fieldId}`,{
                                        state: {blockName: record.blockName, fieldText: record.fieldText, journeyName: record.journeyName}}
                                        )
                                        }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </Tab>
                </Tabs>
            </Modal>
        </div>
    )
}