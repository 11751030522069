import { useParams } from 'react-router-dom';
import { AppLayout, useAppContext } from 'templates/AppLayout/AppLayout';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from 'react';
import { Journeys } from './tabs/Journeys/Journeys';
import { ActionsMenu } from './components/ActionsMenu/ActionsMenu';
import { Overview } from './tabs/Overview/Overview';
import { History } from './tabs/History/History';
import { Diaries } from './tabs/Diaries/Diaries';
import { Records } from './tabs/Records/Records';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useNavigate } from "react-router-dom";

const EXTERNAL_STAKEHOLDER_QUERY = loader("src/graphql/queries/external_stakeholder.graphql");
const RESEND_STAKEHOLDER_JOURNEY = loader("src/graphql/mutations/resend_stakeholder_journey.graphql");
const DELETE_EXTERNAL_STAKEHOLDERS = loader("src/graphql/mutations/delete_external_stakeholders.graphql")


export const StakeholderProfileScreen = ({}) => {

  const { external_stakeholder_id } = useParams();

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const [externalStakeholder, setStakeholder] = useState({});

  const [openModal , setOpenModal] = useState(false)

  const navigate = useNavigate();

  const { loading: fetching, refetch: refetchStakeholder } = useQuery(EXTERNAL_STAKEHOLDER_QUERY, {
    variables: { id: external_stakeholder_id },
    onCompleted: (data) => {
      setStakeholder({})
      setStakeholder(data.externalStakeholder);
    }
  });

  const [ resendJourneyInvite, { loading: resendingJourneyInvite } ] = useMutation(RESEND_STAKEHOLDER_JOURNEY, {
    onCompleted: (data) => {
      alert('Journey Invite was successfully sent')
    },
    onError: error => {
      triggerUnexpectedError(error.networkError.result.errors[0].message)
    }
  });

  const [deleteExternalStakeholder, { loading: deleting }] = useMutation(DELETE_EXTERNAL_STAKEHOLDERS, {
    onCompleted: (data) => {
      alert('Stakeholder successfully Deleted');
      navigate('/')
    },
    onError: () => {
      triggerUnexpectedError();
    },
  });


  useEffect(() => { setLoading(fetching || resendingJourneyInvite || deleting) }, [fetching, resendingJourneyInvite, deleting])
  
  return (
    <>
        <ConfirmationModal 
        visible={openModal}
        title= {`Permanently Delete Stakeholder Confirmation`}
        subtitle={`This Stakeholder will be completely deleted`}
        confirmationIcon={'trash-2'}
        onClose={() => {
          setOpenModal(false)
        }}
        onConfirmation={()=>{
          setOpenModal(false)
          deleteExternalStakeholder({variables: {ids: [external_stakeholder_id]}})
        }}
        buttonTitle="Permanently Delete"
      />
      <AppLayout.Header>
        <AppLayout.Title>
          {externalStakeholder?.name}
          <ActionsMenu onDelete={()=>setOpenModal(true)}/>
        </AppLayout.Title>
      </AppLayout.Header>
      <AppLayout.Body>
        <Tabs defaultActiveKey='overview'>
          <Tab eventKey='overview' title='Overview'>
            <Overview refetchStakeholder={refetchStakeholder} stakeholder={externalStakeholder} setStakeholder={setStakeholder} />
          </Tab>
          <Tab eventKey='history' title='History'>
            <History externalStakeholder={externalStakeholder} />
          </Tab>
          <Tab eventKey="journeys" title='Journeys'>
            <Journeys alert={alert} triggerError={triggerUnexpectedError} externalStakeholder={externalStakeholder} resendFunction={resendJourneyInvite} />
          </Tab>
          {/* <Tab eventKey='diaries' title='Diaries'>
            <Diaries alert={alert} setLoading={setLoading} triggerUnexpectedError={triggerUnexpectedError}  stakeholder={externalStakeholder} />
          </Tab> */}
          <Tab eventKey='records' title='Records'>
            <Records id={external_stakeholder_id} setLoading={setLoading} title="Records" fieldType="record"/>
          </Tab>
          <Tab eventKey='evidences' title='Evidence'>
            <Records id={external_stakeholder_id} setLoading={setLoading} title="Evidence" fieldType="evidence"/>
          </Tab>
        </Tabs>
      </AppLayout.Body>
    </>
  )
}